const { DateTime } = require('luxon');

function isValid({ startTime, endTime }) {
  const end = new DateTime.fromFormat(
    endTime,
    'yyyy-MM-dd HH:mm:ss.u'
  ).toMillis();
  const start = new DateTime.fromFormat(
    startTime,
    'yyyy-MM-dd HH:mm:ss.u'
  ).toMillis();
  const now = new Date().getTime();
  return start < now && end > now;
}

function isAuthorUser(authorType) {
  return ({ author }) => {
    return author === authorType;
  };
}

function sortByPriorityAndEnd(messageA, messageB) {
  const endA = new DateTime.fromFormat(
    messageA.startTime,
    'yyyy-MM-dd HH:mm:ss.u'
  ).toMillis();
  const endB = new DateTime.fromFormat(
    messageB.startTime,
    'yyyy-MM-dd HH:mm:ss.u'
  ).toMillis();
  return endB - endA;
}

function isNotInArray(array) {
  return function(ref) {
    return array.findIndex(val => val === ref) === -1;
  };
}

function filterDismissed({ messages, dismissed }) {
  return messages.filter(({ messageId }) => !dismissed.includes(messageId));
}

export function selectSortedMessages({ messageBanner: { messages } }) {
  return messages.filter(isValid).sort(sortByPriorityAndEnd);
}

export function selectUserSortedMessagesByAuthor(
  { messageBanner: { messages, dismissed } },
  authorType
) {
  const isNotDismissed = ({ messageId }) => isNotInArray(dismissed)(messageId);
  return messages
    .filter(isNotDismissed)
    .filter(isValid)
    .filter(isAuthorUser(authorType))
    .sort(sortByPriorityAndEnd);
}

export function selectMessagesByAuthor(authorType) {
  return ({ messageBanner: { messages } }) => {
    return messages
      .filter(isValid)
      .filter(isAuthorUser(authorType))
      .sort(sortByPriorityAndEnd);
  };
}

export function selectUserSortedMessages({
  messageBanner: { messages, dismissed },
}) {
  const isNotDismissed = ({ messageId }) => isNotInArray(dismissed)(messageId);
  return messages
    .filter(isNotDismissed)
    .filter(isValid)
    .sort(sortByPriorityAndEnd)
    .slice(0, 3);
}

export function selectSortedHeaderMessages({ messageBanner }) {
  return filterDismissed(messageBanner).filter(
    ({ headerMessage }) => headerMessage
  );
}
